import { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Typography, Button, IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useSnackbar } from 'notistack';
import { getDivzeroJourneys } from '../../utils/api/GET'; // Ajuste o caminho conforme necessário
import { BASE_JOURNEY_URL } from '../../utils/api'; // Ajuste o caminho conforme necessário
import FileUploadDialog from '../../components/FileUploadDialog/FileUploadDialog'; // Componente para upload de arquivo

function DivzeroJourney() {
    const [journeys, setJourneys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0); // Total de itens no backend
    const [currentPage, setCurrentPage] = useState(0); // Página atual
    const [nextPage, setNextPage] = useState(null); // URL da próxima página
    const [previousPage, setPreviousPage] = useState(null); // URL da página anterior
    const [showUploadDialog, setShowUploadDialog] = useState(false); // Controle do diálogo
    const { enqueueSnackbar } = useSnackbar();
    const currentUrlRef = useRef(null);

    // Função para buscar jornadas com base em uma URL específica
    const fetchJourneys = (url = null, silent = false) => {
        if (!silent) setLoading(true);

        const endpoint = url || `${BASE_JOURNEY_URL}/journey/journeys/divzero/`;
        currentUrlRef.current = endpoint;

        getDivzeroJourneys(endpoint)
            .then((response) => {
                const { results, count, next, previous } = response.data;

                // Atualiza os dados do estado com os valores retornados pelo backend
                setJourneys(results);
                setPageCount(count);
                setNextPage(next); // Define a URL da próxima página
                setPreviousPage(previous); // Define a URL da página anterior
            })
            .catch(() => {
                if (!silent) {
                    enqueueSnackbar('Erro ao buscar jornadas. Tente novamente.', {
                        variant: 'error',
                        autoHideDuration: 2500,
                    });
                }
            })
            .finally(() => {
                if (!silent) setLoading(false);
            });
    };

    useEffect(() => {
        fetchJourneys(); // Chamada inicial para carregar a primeira página

        const pollingInterval = setInterval(() => {
            fetchJourneys(currentUrlRef.current, true);
        }, 10000);

        return () => clearInterval(pollingInterval);
    }, []);

    // Função para lidar com a mudança de página
    const handlePageChange = (newPage) => {
        if (newPage > currentPage && nextPage) {
            // Se a nova página for maior e houver próxima página
            setCurrentPage(newPage);
            fetchJourneys(nextPage);
        } else if (newPage < currentPage && previousPage) {
            // Se a nova página for menor e houver página anterior
            setCurrentPage(newPage);
            fetchJourneys(previousPage);
        }
    };

		const handleDownload = (url, title) => {
			fetch(url)
					.then(response => response.blob())
					.then(blob => {
							const blobUrl = URL.createObjectURL(blob);
							const anchor = document.createElement('a');
							const extension = url.split('.').pop().split('?')[0];
							const filename = `${title}.${extension}`;

							anchor.href = blobUrl;
							anchor.download = filename;
							document.body.appendChild(anchor);
							anchor.click();
							document.body.removeChild(anchor);

							URL.revokeObjectURL(blobUrl);
					})
					.catch(error => console.error('Erro ao baixar o arquivo:', error));
	};

    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'title', headerName: 'Título', flex: 0.2 },
				{
					field: 'sms_count', headerName: 'SMS', flex: 0.2,
					renderCell: (params) => {
						return (
							<div>
								{params.row.statistics?.SMS || 0}
							</div>
						);
					}
				},
				{
					field: 'whatsapp_count', headerName: 'WHATSAPP', flex: 0.2,
					renderCell: (params) => {
						return (
							<div>
								{params.row.statistics?.WHATSAPP || 0}
							</div>
						);
					}
				},
				{
					field: 'report',
					headerName: 'Relatório',
					sortable: false,
					editable: false,
					width: 150,
					align: 'center',
					headerAlign: 'center',
					renderCell: params => (
						<IconButton
              onClick={() => handleDownload(params.row.report_file_url, params.row.title)}
              disabled={!params.row.report_file_url}
              style={{ opacity: params.row.report_file_url ? '1' : '0.3' }}
            >
              <Tooltip
								title="Baixar relatório"
							>
								<DownloadIcon color="primary" />
							</Tooltip>
            </IconButton>
					),
				},
    ];

    return (
        <div style={{ width: '100%', marginTop: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>
                <Typography variant="h6" gutterBottom>
                    Lista de Jornadas
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowUploadDialog(true)}
                    style={{ marginLeft: '20px' }}
                >
                    Nova Jornada
                </Button>
            </div>

            <FileUploadDialog
                open={showUploadDialog}
                onClose={() => setShowUploadDialog(false)}
                onUploadSuccess={() => {
                    fetchJourneys(); // Atualiza a lista após o upload
                }}
            />

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ width: '100%' }}>
                    <DataGrid
                        rows={journeys}
                        columns={columns}
                        rowCount={pageCount} // Total de itens
                        pageSize={40} // Tamanho fixo da página
                        pagination
                        paginationMode="server"
                        page={currentPage} // Página atual
                        onPageChange={handlePageChange} // Troca de página
                        rowsPerPageOptions={[40]} // Opção única de itens por página
                        sx={{
                            border: 'none',
                            width: '100%', // Garante 100% de largura
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default DivzeroJourney;
